 li{
    list-style: none;
}
#menuCover {
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
}

#menuHeader {
    width: 100%;
    height: 70px;
    border: 1px solid black
}

.menuMainbtn {
    margin-top: 2px;
    position: relative;
    width: 100%;
    height: 50px !important;
}

.menuMainCaption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

/* Sub menu */

.menuSubbtn {
    margin-top: 1px;
    width: 100%;
    height: 50px !important;
}


.menuSubCaption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 60px;
    width: 100%;
    font-size: 12px;
    text-align: left;    

}
/* Layers sub li (checkbox) */
.layerSubLabel{
    position: relative;
    top: 6px;
    margin-left: 20px;
    
}
.layerSubLi{
    height: 40px;
    border-bottom: 1px solid #000000
}
.menuSubIcon{
    float: left;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
}
.MuiTypography-body1{
    font-size: 0.875rem !important
}
a:hover{
    text-decoration: none !important
}