#map{
    height: 100vh !important;
    position: absolute;
    width: 100%;
    /* background-color: rgb(171, 213, 250) */
}
#lng{
    position: absolute;  
    z-index: 999;
    right: 10px;
    font-family: 'Segoe UI';
}
#lng a{
    color :#F26157;
    margin: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    
}
#btnMnu{
    position: absolute;
    width: 44px !important;
    height: 44px !important;
    line-height: 56px !important;
    left: 40px;
    top:30px;
}
.ol-zoom-in{
    width: 40px !important;
    height: 40px !important;
    border-radius:40px !important;
    line-height: 0px !important;
    cursor: pointer;
    background-color: #263238 !important;
    outline: none !important;
}
.ol-zoom-out{
    width: 40px !important;
    height: 40px !important;
    border-radius:40px !important;
    line-height: 0px !important;
    cursor: pointer;
    background-color: #263238 !important;
    outline: none !important;
}
.ol-zoom {
    left: unset;
    right: 8px;
    bottom: 10px;
    top: unset;
    cursor: pointer;
 }
 .ol-unselectable{
     background: transparent
 }
 .ol-unselectable:hover{
    background: transparent
}