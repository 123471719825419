#popupWrap {
    width: 460px;
    height: 180px;
    position: absolute;
    z-index: 1;
    right: -430px;
    top: -190px;
    background-color: rgb(255, 255, 255);
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    /* pointer-events: none; */
    /*Eger aktiv olarsa popup uzerinde hech bir shey secilmez*/
    border-radius: 3px;
    border: #FE5F55 1px solid;
    display: none
}

#closePanel {
    width: 100%;
    height: 21px;
    background-color: #FE5F55;
    border-radius: 3px 3px 0 0;
}

#closButton {
    color: #fff;
    border-radius: 20px;
    margin-right: 0px;
    width: 18px;
    height: 18px;
    float: right;
    text-align: center;
    line-height: 14px;
    cursor: pointer;
}

#closButton:hover {
    color: rgb(243, 232, 232);
}

#popupWrap::after {
    z-index: -9;
    position: absolute;
    height: 16px;
    width: 16px;
    left: 30px;
    top: 100.5%;
    margin-left: -8px;
    margin-top: -8px;
    background-color: rgb(255, 255, 255);
    content: '';
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: #FE5F55 1px solid;
    border-bottom: #FE5F55 1px solid;
}

#popupWrap::before {
    bottom: -16px;
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 6px;
    content: '';
    left: 27px;
    background: #FE5F55;
}

#popContent {
    float: left;
    width: 60%;
    height: 100%;
}

#popUpImage {
    float: left;
    width: 40%;
    height: 100%;
}

.pImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 80%;
}

.MuiTableRow-root td{
   padding: 0 !important;
   padding-left: 6px !important;
   font-size: 12px !important;

}
.wlnk{
    text-decoration: none;
    font-weight: bold;
    color: #1C448E
}