*{
  margin: 0;
  padding: 0
}
#mnuButton{
  width: 36px;
  height: 36px;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 20px;
  cursor: pointer;
}
#mnuButton:hover{
  background-color: rgb(245, 245, 239);
}
#mnuButton:active{
  background-color: rgb(226, 226, 213);
}
.flagsContainer{
  right: 60px;
  top: 50%;
  -webkit-transform: translateY(-45%);
          transform: translateY(-45%);
  position: absolute;
}
.lFlags{
  margin-left: 6px;
  cursor: pointer
}