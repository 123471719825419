#popupWrap {
    width: 460px;
    height: 180px;
    position: absolute;
    z-index: 1;
    right: -430px;
    top: -190px;
    background-color: rgb(255, 255, 255);
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    /* pointer-events: none; */
    /*Eger aktiv olarsa popup uzerinde hech bir shey secilmez*/
    border-radius: 3px;
    border: #FE5F55 1px solid;
    display: none
}

#closePanel {
    width: 100%;
    height: 21px;
    background-color: #FE5F55;
    border-radius: 3px 3px 0 0;
}

#closButton {
    color: #fff;
    border-radius: 20px;
    margin-right: 0px;
    width: 18px;
    height: 18px;
    float: right;
    text-align: center;
    line-height: 14px;
    cursor: pointer;
}

#closButton:hover {
    color: rgb(243, 232, 232);
}

#popupWrap::after {
    z-index: -9;
    position: absolute;
    height: 16px;
    width: 16px;
    left: 30px;
    top: 100.5%;
    margin-left: -8px;
    margin-top: -8px;
    background-color: rgb(255, 255, 255);
    content: '';
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: #FE5F55 1px solid;
    border-bottom: #FE5F55 1px solid;
}

#popupWrap::before {
    bottom: -16px;
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 6px;
    content: '';
    left: 27px;
    background: #FE5F55;
}

#popContent {
    float: left;
    width: 60%;
    height: 100%;
}

#popUpImage {
    float: left;
    width: 40%;
    height: 100%;
}

.pImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 80%;
}

.MuiTableRow-root td{
   padding: 0 !important;
   padding-left: 6px !important;
   font-size: 12px !important;

}
.wlnk{
    text-decoration: none;
    font-weight: bold;
    color: #1C448E
}
#popupWrapTrsmIncs{
    width: 200px;
    height: 70px;
    position: absolute;
    z-index: 1;
    right: -172px;
    top:-86px;
    background-color: rgb(255, 255, 255);
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    /* pointer-events: none; */ /*Eger aktiv olarsa popup uzerinde hech bir shey secilmez*/
    border-radius: 3px;
    border: #FE5F55 1px solid;
    display: none
}
#popupWrapTrsmIncs::after{
    z-index: -9;
    position: absolute;
    height: 16px;
    width: 16px;
    left: 30px;
    top: 100.5%;
    margin-left: -8px;
    margin-top: -8px;
    background-color: rgb(255, 255, 255);
    content: '';
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: #FE5F55 1px solid;
    border-bottom: #FE5F55 1px solid;

}
#popupWrapTrsmIncs::before{
    bottom: -16px;
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 6px;
    content: '';
    left:27px;
    background: #FE5F55;
}
.trsmPntsTextAditional{
    width: 100% !important;
    height: 100% !important;
    line-height: 34px !important;
    margin: auto;
    text-align: center;
    font-size: 14px;
    color: #FE5F55
}
#popupWrapZooIncs{
    width: 230px;
    height: 234px;
    position: absolute;
    z-index: 1;
    right: -202px;
    top:-254px;
    background-color: rgb(255, 255, 255);
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    /* pointer-events: none; */ /*Eger aktiv olarsa popup uzerinde hech bir shey secilmez*/
    border-radius: 3px;
    border: #FE5F55 1px solid;
    display: none
}
#popupWrapZooIncs::after{
    z-index: -9;
    position: absolute;
    height: 16px;
    width: 16px;
    left: 30px;
    top: 100.5%;
    margin-left: -8px;
    margin-top: -8px;
    background-color: rgb(255, 255, 255);
    content: '';
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: #FE5F55 1px solid;
    border-bottom: #FE5F55 1px solid;

}
#popupWrapZooIncs::before{
    bottom: -16px;
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 6px;
    content: '';
    left:27px;
    background: #FE5F55;
}
.zooPntsTextAditional{
    line-height: 34px !important;
    margin: auto;
    text-align: center;
    font-size: 14px;
    color: #FE5F55
}
#popUpBody{
    height: 250px;
    width: 100%;
}
#OrgImage{

    position: relative;
}
#OrgImage img{
    max-width: 100%;
    max-height: 100%;
}
#popContentZoo{
    position: absolute;
    width: 100%;
    bottom: 0
}
 li{
    list-style: none;
}
#menuCover {
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
}

#menuHeader {
    width: 100%;
    height: 70px;
    border: 1px solid black
}

.menuMainbtn {
    margin-top: 2px;
    position: relative;
    width: 100%;
    height: 50px !important;
}

.menuMainCaption {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
}

/* Sub menu */

.menuSubbtn {
    margin-top: 1px;
    width: 100%;
    height: 50px !important;
}


.menuSubCaption {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 60px;
    width: 100%;
    font-size: 12px;
    text-align: left;    

}
/* Layers sub li (checkbox) */
.layerSubLabel{
    position: relative;
    top: 6px;
    margin-left: 20px;
    
}
.layerSubLi{
    height: 40px;
    border-bottom: 1px solid #000000
}
.menuSubIcon{
    float: left;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 18px;
    width: 18px;
}
.MuiTypography-body1{
    font-size: 0.875rem !important
}
a:hover{
    text-decoration: none !important
}
#MapLegend{
    position: absolute;
    width: 400px;
    bottom: 0;
    left: 4px;
    z-index: 6;
    overflow: auto;
    padding: 0px 10px 10px 10px;
}
.tblFrstClm{
    width: 80px;
    height: 80px;
    line-height: 78px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    margin:auto;
}
.tblFrstClmIcon{
    width: 40px;
    height: 40px;
    line-height: 70px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    margin:auto;
}

.tblSecondClm{
    width: 100%;
    height: 100%;
    text-align: center;
    color: #000000;
    font-size: 13px;

}
.legendCover{
    height: 460px;
    overflow: auto;
}
.legendHeader{
    height: 30px;
    line-height: 30PX;
    text-align: center;
    font-weight: bold
}
#map{
    height: 100vh !important;
    position: absolute;
    width: 100%;
    /* background-color: rgb(171, 213, 250) */
}
#lng{
    position: absolute;  
    z-index: 999;
    right: 10px;
    font-family: 'Segoe UI';
}
#lng a{
    color :#F26157;
    margin: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    
}
#btnMnu{
    position: absolute;
    width: 44px !important;
    height: 44px !important;
    line-height: 56px !important;
    left: 40px;
    top:30px;
}
.ol-zoom-in{
    width: 40px !important;
    height: 40px !important;
    border-radius:40px !important;
    line-height: 0px !important;
    cursor: pointer;
    background-color: #263238 !important;
    outline: none !important;
}
.ol-zoom-out{
    width: 40px !important;
    height: 40px !important;
    border-radius:40px !important;
    line-height: 0px !important;
    cursor: pointer;
    background-color: #263238 !important;
    outline: none !important;
}
.ol-zoom {
    left: unset;
    right: 8px;
    bottom: 10px;
    top: unset;
    cursor: pointer;
 }
 .ol-unselectable{
     background: transparent
 }
 .ol-unselectable:hover{
    background: transparent
}
*{
  margin: 0;
  padding: 0
}
#mnuButton{
  width: 36px;
  height: 36px;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 20px;
  cursor: pointer;
}
#mnuButton:hover{
  background-color: rgb(245, 245, 239);
}
#mnuButton:active{
  background-color: rgb(226, 226, 213);
}
.flagsContainer{
  right: 60px;
  top: 50%;
  -webkit-transform: translateY(-45%);
          transform: translateY(-45%);
  position: absolute;
}
.lFlags{
  margin-left: 6px;
  cursor: pointer
}
