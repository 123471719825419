#popupWrapZooIncs{
    width: 230px;
    height: 234px;
    position: absolute;
    z-index: 1;
    right: -202px;
    top:-254px;
    background-color: rgb(255, 255, 255);
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    /* pointer-events: none; */ /*Eger aktiv olarsa popup uzerinde hech bir shey secilmez*/
    border-radius: 3px;
    border: #FE5F55 1px solid;
    display: none
}
#popupWrapZooIncs::after{
    z-index: -9;
    position: absolute;
    height: 16px;
    width: 16px;
    left: 30px;
    top: 100.5%;
    margin-left: -8px;
    margin-top: -8px;
    background-color: rgb(255, 255, 255);
    content: '';
    transform: rotate(45deg);
    border-right: #FE5F55 1px solid;
    border-bottom: #FE5F55 1px solid;

}
#popupWrapZooIncs::before{
    bottom: -16px;
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 6px;
    content: '';
    left:27px;
    background: #FE5F55;
}
.zooPntsTextAditional{
    line-height: 34px !important;
    margin: auto;
    text-align: center;
    font-size: 14px;
    color: #FE5F55
}
#popUpBody{
    height: 250px;
    width: 100%;
}
#OrgImage{

    position: relative;
}
#OrgImage img{
    max-width: 100%;
    max-height: 100%;
}
#popContentZoo{
    position: absolute;
    width: 100%;
    bottom: 0
}