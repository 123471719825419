#MapLegend{
    position: absolute;
    width: 400px;
    bottom: 0;
    left: 4px;
    z-index: 6;
    overflow: auto;
    padding: 0px 10px 10px 10px;
}
.tblFrstClm{
    width: 80px;
    height: 80px;
    line-height: 78px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    margin:auto;
}
.tblFrstClmIcon{
    width: 40px;
    height: 40px;
    line-height: 70px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    margin:auto;
}

.tblSecondClm{
    width: 100%;
    height: 100%;
    text-align: center;
    color: #000000;
    font-size: 13px;

}
.legendCover{
    height: 460px;
    overflow: auto;
}
.legendHeader{
    height: 30px;
    line-height: 30PX;
    text-align: center;
    font-weight: bold
}